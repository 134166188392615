<template>

    Tagging der Images

    <v-row>
        <v-checkbox
          v-model="trainmodels"

        ></v-checkbox>

        <v-text-field
              label="Anzahl Bilder"
              v-model="anzahl_Bilder"
              type="input"
        ></v-text-field>
        <v-progress-linear
         :indeterminate="isLoading"></v-progress-linear>
        <v-btn @click="TagAllImages" block>Tag All images</v-btn>
    </v-row>

    meine unknowns - wenn nur 20 kommen ist in API zum test limit gesetzt!

    <v-row
          v-for="u in unknowns"
          :key="u"
          class="d-none d-sm-flex"
        >
        {{u}}

            <div @click="showImage(u.Bild_URL)" :style="{ backgroundImage : 'url(' + encodeURI(u.Bild_URL) + ')', 'background-size': '600px',  width: '100%', height: '200px'}">
            </div>


    </v-row>


    <v-dialog v-model="dialog" fullscreen transition="dialog-bottom-transition" :overlay=false>

              <v-card>
                <v-toolbar dark color="primary">
                  <v-btn icon @click="dialog = false" dark>
                    <v-icon icon="mdi-close"></v-icon>
                  </v-btn>
                  <v-toolbar-title>Vollbild</v-toolbar-title>

                </v-toolbar>


                hier URL {{VollbildURL}}
                 <v-img
                    :src='VollbildURL'

                  ></v-img>




              </v-card>

            </v-dialog>









</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'TrainObjectsScreen',
  data() {
    return {
      dialog: false,
      VollbildURL: 'not clicked',
      AssemblyID: 0,
      anzahl_Bilder:2000,
      trainmodels: false,
      isLoading: false,
      form: {
        title: '',
        content: '',
      },
      isHovering: true,
    };
  },
  created: function() {
    console.log('Bin in Alben')
    return this.$store.dispatch('getUnknowns');
  },
  computed: {
    ...mapGetters({ unknowns: 'stateUnknowns'}),

  },
  methods: {
    ...mapActions(['createNote']),
    async submit() {
      console.log('TrainObjectsScreen')
    },
    async TagAllImages() {
      console.log('TagAllImages',this.anzahl_Bilder,this.trainmodels)
      this.isLoading = true

      var formData = new FormData();
        formData.append('anzahl_Bilder', this.anzahl_Bilder);
        formData.append('trainmodels', this.trainmodels);

        this.$store.dispatch('tagfaces',formData).then(response => {
                console.log("Got some data, now lets show something in this component",response)
                this.isLoading = false
            }, error => {
                console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
            })



    },
    async showImage(url) {
      console.log('showImage full screen',url)
      this.VollbildURL = url

      this.dialog = true
    },



  },
};
</script>