<template>
    <v-progress-circular
      v-if = "uploadstarted"
      density="compact"
      indeterminate
      color="primary"
    ></v-progress-circular>

    <v-alert
    v-if = "uploadsuccess"
    density="compact"
    type="warning"
    title="Success"
    text="Bild was uploaded!"
    ></v-alert>

    <v-alert
    v-if = "uploaderror"
    density="compact"
    type="warning"
    title="uploaderror"
    text="Bild was NOT UPLOADED!"
    ></v-alert>

    <v-card class="mx-auto">
      <v-card-title>Bild hochladen</v-card-title>
      <v-card-text>
        <v-file-input
          v-model="file"
          label="Bild auswählen"
          accept="image/*"
          show-size
          outlined
          dense
        ></v-file-input>
        <div v-if="file" class="mt-3">
          <p><strong>Dateiname:</strong> {{ file.name }}</p>
          <p><strong>Dateigröße:</strong> {{ (file.size / 1024).toFixed(2) }} KB</p>
          <v-img
            :src="imagePreview"
            alt="Vorschau"
            max-height="200"
            contain
            class="my-3"
          ></v-img>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!file"
          color="primary"
          @click="uploadFile"
        >
          Hochladen
        </v-btn>
      </v-card-actions>
    </v-card>




   <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="assembly_Benennung"
            label="assembly_Benennung"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="Bild_Beschreibung"
            label="Bild_Beschreibung"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="email"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ImageUploadScreen',
  data() {
    return {
      uploadedimage:'',
      uploadsuccess: false,
      uploaderror: false,
      uploadstarted: false,
      assembly_Benennung: 'new Asi von vueti3',
      Bild_Beschreibung: ' ', //muss etwas enthalten sonst funktioniert der Aufruf nicht
      file: null,
      imagePreview: null,

    };
  },
  watch: {
    file(newFile) {
      if (newFile) {
        this.generatePreview(newFile);
      } else {
        this.imagePreview = null;
      }
    },
  },
  created: function() {
    console.log('Bin in ImageUpload')
    //return this.$store.dispatch('getNotes');
  },
  computed: {
    ...mapGetters({ assemblies: 'stateAssemblies'}),
/*    filterDesserts() {
      return this.desserts.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    }*/
  },
  methods: {
  ...mapActions(['createAssembly','createBildmitfiles']),
//    async submit() {
//      console.log('ImageUploadScreen')
//    },
//    onFileChange(e) {
//      console.log('Bin in onFileChange')
//      var files = e.target.files || e.dataTransfer.files;
//      if (!files.length)
//        return;
//      this.uploadedimage = files[0]
//      this.createImage(files[0]);
//    },
//    createImage(file) {
//      var image = new Image();
//      var reader = new FileReader();
//      var vm = this;
//        this.uploadstarted = true
//          console.log('Bin in createImage')
//          var ctx = this.$refs.myCanvas.getContext('2d')
//
//      reader.onload = (e) => {
//        vm.image = e.target.result;
//        var dataURL = e.target.result
//
//          image.onload = function() {
//        //Skalierung ermitteln
//        var MAX_WIDTH = 1000;
//        var MAX_HEIGHT = 600;
//        var width = image.width;
//        var height = image.height;
//
//        if (width > height) {
//          if (width > MAX_WIDTH) {
//          height *= MAX_WIDTH / width;
//          width = MAX_WIDTH;
//          }
//        } else {
//          if (height > MAX_HEIGHT) {
//          width *= MAX_HEIGHT / height;
//          height = MAX_HEIGHT;
//          }
//        }
//            ctx.width = image.width;
//            ctx.height = image.height;
//            ctx.drawImage(image, 0, 0,width,height);
//            vm.createAssemblyV2()
//          };
//
//          image.src = dataURL;
//
//      };
//      reader.readAsDataURL(file);
//    },

    generatePreview(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    uploadFile() {
      if (!this.file) return;

      this.uploadstarted = true
      this.createAssemblyV2()
//      const formData = new FormData();
//      formData.append("file", this.file);
//
//      // Hier ein Beispiel-API-Aufruf
//      fetch("https://example.com/api/upload", {
//        method: "POST",
//        body: formData,
//      })
//        .then((response) => response.json())
//        .then((data) => {
//          alert("Erfolgreich hochgeladen!",data);
//        })
//        .catch((error) => {
//          console.error("Fehler beim Hochladen:", error);
//        });
    },
    async createAssemblyV2() {
      //console.log('AssemblyScreen: Async submit createAssembly')

      var obj = this
        if (navigator.geolocation) {
                await navigator.geolocation.getCurrentPosition(success);
        } else {
                console.log('Geolocation is not supported by this browser')
      }


                function success(position) {

            var latitude  = position.coords.latitude;
            var longitude = position.coords.longitude;
            var latitude_global = latitude
            var longitude_global = longitude


            //var image = obj.$refs.myCanvas.toDataURL("image/png");

            console.log('Paras:',latitude_global,longitude_global)
            //neuen Assembly anlegen
//            var data3 = {
//                  "Sachnummer": "",
//                  "Benennung": "new from vue",
//                  "image" : image,
//                  "Kategorie":1,  //4 ist Barcode als default bei Scanner sonst
//                  "geo_longi_X":longitude_global,
//                  "geo_lati_Y":latitude_global,
//                  "barcode": sessionStorage.getItem('bild_Barcode')
//              }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            var todayOhnetime = yyyy +'-' + mm + '-' + dd;

            var neuerAssembly = {
              "assembly_Benennung": obj.assembly_Benennung,
              "assembly_DatumErstellung": todayOhnetime,
              "assembly_DatumAenderung": today,
              "assembly_Bewertung": 0,
              "Kategorie_idKategorie": 55, //spielt keine Rolle wird am Server gesetzt
              "assembly_Preis": 0,
              "assembly_Beschreibung": "",
              "assembly_Rezension": "",
              "user_iduser_id": 55  //spielt keine Rolle wird am Server gesetzt
            }

            //das mus man als promise then machen
            //obj.createAssembly(neuerAssembly)
            //var neuesAssemblyObject = obj.createAssembly(neuerAssembly);

            obj.createAssembly(neuerAssembly).then((res) => {
                  console.log('asi created:',obj.assemblies[0].idassembly,res)
                  //console.log('neuesAssemblyObject',neuesAssemblyObject, neuesAssemblyObject.idassembly)

                  //wenn erfolgreich dann ein Bild anlegen

                    var formData = new FormData();
                    //formData.append("file",  obj.uploadedimage)
                    formData.append("file",  obj.file)
                    //formData.append('assembly_idassembly_id', obj.assemblies[0].idassembly);
                    formData.append('assembly_idassembly_id', obj.assemblies[0].idassembly);
                    formData.append('bilder_geo_longi_X', longitude)
                    formData.append('bilder_geo_lati_Y', latitude)
                    formData.append('Bilder_Benennung', obj.Bild_Beschreibung)


                    //this.$store.dispatch('getStockhistories',ausgewaehlteStockIDs)

                    obj.$store.dispatch('createBildmitfiles',formData).then(response => {
                        console.log("Got some data, now lets show something in this component",response)
                        obj.uploadsuccess = true
                        obj.uploadstarted = false

                    }, error => {
                        console.error("Got nothing from server. Prompt user to check internet connection and try again",error)
                        obj.uploaderror = false
                    })
                })
                .catch((error) => {
                  console.error(error);
                });









          }//End funtion position success

    },


  },
};
</script>